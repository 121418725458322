import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import animationData from 'animations/home/business-growth/business-growth.json';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import useLottie from 'hooks/use-lottie';
import GraphGrowthIcon from 'icons/graph-growth.inline.svg';

const BusinessGrowth = () => {
  const [animationVisibilityRef, inView] = useInView({
    threshold: 0.2,
  });

  const [isAnimationReady, setIsAnimationReady] = useState(false);

  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
      complete() {
        this.loop = true;
        this.playSegments([25, 126], true);
      },
    }
  );

  useEffect(() => {
    if (!animation || !isAnimationReady) return;
    if (inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, isAnimationReady, inView]);
  return (
    <section className="mt-28 lg:overflow-hidden lg:mt-32 xl:mt-48">
      <Container className="flex flex-col lg:grid lg:items-center lg:grid-cols-12 lg:gap-x-8">
        <div className="lg:col-start-1 lg:col-end-6">
          <GraphGrowthIcon />
          <Heading className="mt-8" tag="h2" size="md">
            Ignite Business Growth and Marketing Conversion
          </Heading>
          <p className="mt-5 text-lg lg:leading-normal lg:text-xl">
            Yobi’s combination of advanced machine learning technology with high quality behavioral
            data more accurately identifies prospective customers across digital, mobile, OOH, and
            TV.
          </p>
          <Link className="mt-8" type="arrow" theme="primary-2" to="/growth">
            Learn more
          </Link>
        </div>
        <div
          className="flex justify-center lg:col-start-7 lg:col-end-13 lg:-mr-10 xl:-mr-14"
          ref={animationVisibilityRef}
        >
          <div className="max-w-[644px]" ref={animationRef} />
        </div>
      </Container>
    </section>
  );
};

export default BusinessGrowth;
