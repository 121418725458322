import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import PanelSvg from 'icons/panel.inline.svg';

const title = 'Yobi’s Behavioral Genome was Forged at the Intersection of Consumer Behavior and AI';
const text =
  'We leverage machine learning to produce statistical representations of customer behavior across Yobi’s Behavioral Graph without sacrificing privacy or quality.';
const linkText = 'Explore Technology';
const linkUrl = '/behavior-genome';

const BehavioralGenome = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pages/home/behavioral-genome/image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  `);
  return (
    <section className="overflow-hidden lg:mt-36 gradient-background-gray">
      <Container>
        <div className="flex flex-col lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="relative order-1 hidden mt-10 mr-0 sm:block lg:mt-0 lg:col-start-1 lg:col-end-6 md:-mr-2 lg:-mr-8 before:absolute before:top-0 before:w-screen before:h-full before:bg-secondary-1 before:translate-x-4 before:right-0">
            <div className="flex justify-center lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:-right-4 mx-auto max-w-[500px] xl:max-w-[700px] xl:w-[700px]">
              <GatsbyImage className="2xl:right-[5vw]" image={getImage(image)} alt="" />
            </div>
          </div>
          <div className="mt-20 mb-20 sm:mb-0 lg:relative lg:py-16 xl:py-32 lg:mt-0 lg:row-start-1 lg:col-start-6 lg:col-end-13 lg:flex lg:flex-col lg:justify-center lg:pl-23">
            <PanelSvg />
            <Heading className="mt-8 lg:text-4xl xl:text-5xl" tag="h2" size="lg" theme="black">
              {title}
            </Heading>
            <p className="mt-5 text-xl leading-normal lg:text-2xl">{text}</p>
            <Link className="mt-8" to={linkUrl} type="arrow" theme="primary-2">
              {linkText}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BehavioralGenome;
