import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import animationData from 'animations/home/personalize-experience/personalize-experience.json';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import useLottie from 'hooks/use-lottie';
import LockIcon from 'icons/lock.inline.svg';

const PersonalizeExperience = () => {
  const [animationVisibilityRef, inView] = useInView({
    threshold: 0.2,
  });

  const [isAnimationReady, setIsAnimationReady] = useState(false);

  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
      complete() {
        this.loop = true;
        this.playSegments([25, 126], true);
      },
    }
  );

  useEffect(() => {
    if (!animation || !isAnimationReady) return;
    if (inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, isAnimationReady, inView]);
  return (
    <section className="mt-24 lg:mt-32 xl:mt-40">
      <Container className="flex flex-col lg:grid lg:items-center lg:grid-cols-12 lg:gap-x-8">
        <div
          className="flex justify-center order-1 mt-10 lg:justify-start lg:order-none lg:col-start-1 lg:col-end-8"
          ref={animationVisibilityRef}
        >
          <div className="max-w-[636px]" ref={animationRef} />
        </div>
        <div className="lg:col-start-8 lg:col-end-13">
          <LockIcon />
          <Heading className="mt-8" tag="h2" size="md">
            Safely Personalize Every Experience
          </Heading>
          <p className="mt-5 text-lg lg:leading-normal lg:text-xl">
            Utilize privacy preserving predictive signals to enhance engagement, monetization, and
            relevance with your customers.
          </p>
          <Link className="mt-8" type="arrow" theme="primary-1" to="/personalization">
            Learn more
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default PersonalizeExperience;
