import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import Icon1 from './images/icon-1.inline.svg';
import Icon2 from './images/icon-2.inline.svg';
import Icon3 from './images/icon-3.inline.svg';

const items = [
  {
    icon: Icon1,
    title: 'Decrease Security Risk of Breaches',
    description:
      'Yobi’s Machine Readable Data contains all of the signal without the possibility of revealing or reverse engineering personal information.',
  },
  {
    icon: Icon2,
    title: 'Empower Ethically Competitive Data Science',
    description:
      'Optimize data science capabilities without sacrificing customer privacy or trust.',
  },
  {
    icon: Icon3,
    title: 'Eliminate Data Leakage',
    description: 'Centralize your data acquisition strategy through Yobi’s Platform.',
  },
];

const PrivacyCentricBusinesses = () => (
  <section className="mt-24 lg:mt-32 xl:mt-40">
    <Container>
      <Heading className="lg:text-center" tag="h2" size="lg" theme="black">
        Yobi Powers Privacy Centric Businesses
      </Heading>
      <div className="grid grid-cols-1 gap-8 mt-10 lg:mt-16 lg:grid-cols-3">
        {items.map(({ icon: Icon, title, description }, index) => (
          <CardWithIcon title={title} description={description} icon={Icon} key={index} />
        ))}
      </div>
    </Container>
  </section>
);

export default PrivacyCentricBusinesses;
