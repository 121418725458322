import React from 'react';

import BehavioralGenome from 'components/pages/home/behavioral-genome';
import BusinessGrowth from 'components/pages/home/business-growth';
import Hero from 'components/pages/home/hero';
import PersonalizeExperience from 'components/pages/home/personalize-experience';
import PrivacyCentricBusinesses from 'components/pages/home/privacy-centric-businesses';
import Profile from 'components/pages/home/profile';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const MainPage = () => (
  <MainLayout pageMetadata={SeoMetadata.home}>
    <Hero />
    <BehavioralGenome />
    <Profile />
    <BusinessGrowth />
    <PersonalizeExperience />
    <PrivacyCentricBusinesses />
  </MainLayout>
);

export default MainPage;
