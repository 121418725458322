import React from 'react';

import animationData from 'animations/home/profile/diagram.json';
import Scheme from 'components/shared/scheme';


const Profile = () => (
  <Scheme
    className="mt-24 lg:mt-32 xl:mt-40"
    title="Build a Complete Profile of Customer Behavior"
    titleSize="md"
    description="Unify your customer data with Yobi’s Behavioral Graph to safely access one of the
  world’s largest consent based datasets."
    linkUrl="/personalization"
    animationData={animationData}
  />
);

export default Profile;
