import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import RequestDemo from 'components/shared/request-demo';

const Hero = () => (
  <section className="mt-10 mb-20 sm:mb-0 lg:overflow-hidden">
    <Container className="flex flex-col lg:flex-row">
      <div className="lg:pr-2 xl:pr-0 lg:w-1/2 xl:max-w-[592px] xl:py-18">
        <Heading className="mb-5" tag="h1" size="xl" theme="black" withHighlightedWords>
          Harness the Power of Predictive <span>Data Science</span>
        </Heading>
        <p className="lg:max-w-[470px] text-xl lg:text-2xl leading-normal mb-8">
          Optimize revenue and customer experience in a privacy centric ecosystem.
        </p>
        <RequestDemo className="md:max-w-[592px] hidden sm:flex" />
      </div>

      <video
        className="py-10 mx-auto lg:mx-0 lg:pl-2 xl:pl-0  lg:py-0 lg:w-1/2 xl:absolute top-0 -right-8 2xl:-right-14 xl:w-[607px]"
        src="/animations/pages/home/hero/hero.mp4"
        autoPlay
        muted
        playsInline
        loop
      />
      <RequestDemo className="md:max-w-[592px] flex sm:hidden" />
    </Container>
  </section>
);

export default Hero;
